/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import RelatedItemsWrapper from './index.style';
import mathHelper from '../../utils/math';


/*------------------------------------------------*/
/* COMPONENT
/*------------------------------------------------*/
const RelatedItems = (props) => {
  const {
    title,
    items,
    limit,
  } = props;

  const [filteredItems, setFilteredItems] = React.useState([]);

  React.useEffect(() => {
    if (limit > 0 && items.length > limit) {
      // Get {limit} random items:
      const indexArray = mathHelper.getArrayOfRandomNumbersBetween(0, items.length - 1, limit);
      const newItems = indexArray.map(indexItem => (items[indexItem]));
      setFilteredItems(newItems);
    }
    else {
      // Just use the available items:
      setFilteredItems(items);
    }
  }, [limit, items]);

  /* RETURN ----------------------------------*/
  if (items.length === 0) {
    return null;
  }
  return (
    <RelatedItemsWrapper>
      <h2>{title}</h2>
      <div className="items-wrapper">
        <ul>
          {filteredItems.map(item => (
            <li key={item.id}>
              <a
                href={item.url}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </RelatedItemsWrapper>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
RelatedItems.defaultProps = {
  title: '',
  items: [],
  limit: 0,
};
RelatedItems.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  limit: PropTypes.number,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default RelatedItems;
