/**
 * Gets a random number between the params (they included!)
 * @param {number} numberFrom
 * @param {number} numberTo
 */
const getRandomNumberBetween = (numberFrom = 0, numberTo = 10) => (
  Math.floor(Math.random() * numberTo) + numberFrom
);

/**
 * Gets an array of unique random numbers.
 * @param {number} numberFrom
 * @param {number} numberTo
 * @param {number} arrayLength
 * @returns
 */
const getArrayOfRandomNumbersBetween = (numberFrom = 0, numberTo = 10, arrayLength = 5) => {
  const myArray = [];

  for (let index = 0; index < arrayLength; index += 1) {
    const randomIndex = getRandomNumberBetween(numberFrom, numberTo);

    // If the item does not exist in the array, I just add it.
    if (myArray.indexOf(randomIndex) < 0) {
      myArray.push(randomIndex);
    }
    else {
      // I try to get a new random number:
      let newRandomNumber = getRandomNumberBetween(numberFrom, numberTo);
      let positionFound = (myArray.indexOf(newRandomNumber) < 0);

      // If it is already in the array, I just iterate until I find one it's not:
      while (positionFound === false) {
        if (newRandomNumber > numberTo) {
          newRandomNumber = 0;
        }

        if (myArray.indexOf(newRandomNumber) < 0) {
          positionFound = true;
        }
        else {
          newRandomNumber += 1;
        }
      }

      myArray.push(newRandomNumber);
    }
  }

  return myArray;
};
export default {
  getRandomNumberBetween,
  getArrayOfRandomNumbersBetween,
};
