/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';


const RelatedItemsWrapper = styled.div`
  .items-wrapper {
    ul {
      margin: 0;
      padding: 0 20px 0 0;
      li {
        list-style-type: none;
        margin: 0 0 12px 0;
      }
    }
  }
`;
RelatedItemsWrapper.displayName = 'RelatedItemsWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default RelatedItemsWrapper;
