/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
// eslint-disable-next-line import/no-cycle
import Layout from '../components/layout';
import Container, {
  ContainerChildrenWrapper,
} from '../components/Container';
import {
  ContentWrap,
  HeroHeading,
} from '../components/Global';
import SEO from '../components/seo';
import HelpArticleContext from '../components/Context/HelpArticleContext';
import ContentfulRichText from '../components/ContentfulRichText';
import HeaderSummary from '../components/HeaderSummary';
import FloatingMenu from '../components/FloatingMenu';
import RelatedItems from '../components/RelatedItems';
import TrackingYesNo from '../components/TrackingYesNo';
// Helpers
import urlHelper from '../utils/url';


const ContentWrapHelpArticle = styled(ContentWrap)`
  img {
    margin: 24px auto;
  }
`;


const HelpArticle = (props) => {
  const {
    data,
    location,
  } = props;
  const { search } = location;
  const qParams = new URLSearchParams(search);
  const isEmbededView = qParams.has('embeded');

  const article = _get(data, 'contentfulHelpArticle', null);
  const subCategories = _get(data, 'allContentfulHelpSubcateogry.nodes', [])
    .map(subCategory => ({
      ...subCategory,
      url: urlHelper.getHelpCategory(
        _get(article, 'subCategory[0].category.slug', ''),
        urlHelper.convertToSlug(subCategory.name)
      ),
    }));
  const selectedCategory = {
    id: _get(article, 'subCategory[0].category.id', ''),
    name: _get(article, 'subCategory[0].category.categoryTitle', ''),
    url: urlHelper.getHelpCategory(_get(article, 'subCategory[0].category.slug', '')),
  };
  const selectedSubCategoryId = _get(article, 'subCategory[0].id', '');
  const sameSubCategoryArticles = _get(data, 'allContentfulHelpArticle.edges', [])
    .map(articleItem => ({
      ...articleItem.node,
      name: _get(articleItem, 'node.title', ''),
      url: urlHelper.getHelpArticle(_get(articleItem, 'node.slug', '')),
    }));
  const seoMeta = {
    title: `${article.title} | Help Center | Workast`,
    description: `${article.excerpt.excerpt.substr(0, 157)}...`
  };

  return (
    <HelpArticleContext.Provider value={article}>
      <Layout
        location={location}
        useDarkBackground
        title={article.title}
        showHeader={!isEmbededView}
        showFooter={!isEmbededView}
        isEmbededView={isEmbededView}
      >
        <SEO
          title={seoMeta.title}
          description={seoMeta.description}
        />
        <Container>
          <ContainerChildrenWrapper>
            <FloatingMenu
              mainItem={selectedCategory}
              items={subCategories}
              selectedItemId={selectedSubCategoryId}
            />
            <ContentWrapHelpArticle>
              <HeroHeading mt="30px" mb="60px">{article.title}</HeroHeading>
              <HeaderSummary
                richText={article.body}
              />
              <ContentfulRichText richText={article.body} />
              <div className="section">
                <TrackingYesNo
                  title="Was this article helpful?"
                  label={article.title}
                />
                {!isEmbededView && (
                  <Fragment>
                    <div className="divider" />
                    <RelatedItems
                      title="Related articles"
                      items={sameSubCategoryArticles}
                      limit={5}
                    />
                  </Fragment>
                )}
              </div>
            </ContentWrapHelpArticle>
          </ContainerChildrenWrapper>
        </Container>
      </Layout>
    </HelpArticleContext.Provider>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
HelpArticle.propTypes = {
  data: PropTypes.shape({
    contentfulHelpArticle: PropTypes.shape({
      body: PropTypes.shape({
        raw: PropTypes.string,
      }),
      excerpt: PropTypes.shape({
        excerpt: PropTypes.string,
      }),
      id: PropTypes.string,
      slug: PropTypes.string,
      subCategory: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          category: PropTypes.shape({
            id: PropTypes.string,
            categoryTitle: PropTypes.string,
          }),
        }),
      ),
      title: PropTypes.string,
      updatedAtISO: PropTypes.string,
    }).isRequired,
    allContentfulHelpSubcateogry: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          order: PropTypes.number,
        })
      ),
    }).isRequired,
    allContentfulHelpArticle: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string,
            title: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default HelpArticle;

export const pageQuery = graphql`
  query HelpArticleQuery($slug: String!, $categoryId: String!, $subCategoryId: String!) {
    contentfulHelpArticle(slug: {eq: $slug}) {
      slug
      id
      title
      excerpt {
        excerpt
      }
      updatedAtISO: updatedAt
      subCategory:category {
        id
        name
        category {
          id
          categoryTitle
          slug
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData
          }
          ... on ContentfulElementTip {
            __typename
            contentful_id
            text {
              text
            }
          }
          ... on ContentfulElementStep {
            __typename
            contentful_id
            number
            content {
              raw
            }
          }
          ... on ContentfulElementVideo {
            __typename
            contentful_id
            url
          }
        }
      }
    }

    allContentfulHelpSubcateogry(
      filter: {category: {id: {eq: $categoryId}}}
      sort: {fields: order, order: ASC}
    ) {
      nodes {
        id
        name
        order
      }
    }

    allContentfulHelpArticle(
      filter: {
        slug: {ne: $slug},
        category: {elemMatch: {id: {eq: $subCategoryId}}}
      }
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`;
